import { TZ_EUROPE_LONDON } from '@packfleet/datetime';
import { useCurrentUser } from './useCurrentUser';

export function useOrganizationTimezone(): string {
  const user = useCurrentUser();
  return user?.organization?.timezone ?? TZ_EUROPE_LONDON;
}

export function useDeviceTimezone(): string {
  if (
    // CanIUse suggests we can use this for all recent browsers / mobile browsers, but this doesn't work in IE.
    // In IE, we'd default to London instead which is fine.
    typeof Intl !== 'undefined' &&
    typeof Intl.DateTimeFormat !== 'undefined' &&
    typeof Intl.DateTimeFormat().resolvedOptions !== 'undefined'
  ) {
    return Intl.DateTimeFormat().resolvedOptions().timeZone;
  }
  return TZ_EUROPE_LONDON;
}
